<template>
  <el-dialog
    :before-close="onDialogClose"
    :close-on-click-modal="false"
    :title="title"
    :showClose="false"
    ref="dateDialog"
    :visible.sync="visible"
    width="60%">
    <template slot="title">
      <el-row>
        <el-col :span="20">
          <h1>{{ title }}</h1>
        </el-col>
        <el-col :span="4" class="child-right-align">
          <el-button round size="small" type="primary" @click="onSave">Save</el-button>
          <el-button round size="small" type="outlined" @click="closeDialog">Close</el-button>
        </el-col>
      </el-row>
    </template>
    <div>
      <el-row :glutter="30" style="margin-bottom:10px;">
        <el-col :span="12">
          <el-select v-model="bunkerwireType" @change="handleChange">
           <el-option
            v-for="item in ORDER_PRICE_RATE_TYPE_OPTIONS"
            :key="item.value" :label="item.label" :value="item.value"
          />
          </el-select>
        </el-col>
      </el-row>

      <el-form ref="mangeMethodologyForm" :model="entity">
        <el-table
        :data="methodList"
        header-row-class-name="table-header-blue"
        height="513"
        style="width: 97%;"
      >
        <el-table-column
          label="Column"
          width="90px"
          align="center"
        >
          <template
            slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>

        </el-table-column>
        <el-table-column
          label="AA"
          width="90px"
        >
          <template slot="header" slot-scope="scope">
            <div style="display:flex;justify-content:center;width:100%">
              <img alt="" src="@/assets/images/updown.svg"/>
            </div>
          </template>
          <template slot-scope="scope">
            <div style="display:flex;justify-content:center;width:100%">
              <em :disabled="scope.$index===0||scope.row.id===null" class="el-icon-caret-top icons-size"
                  @click="() => downIndex(scope.row)"></em>
              <em :disabled="scope.$index===(methodList.length-1)" class="el-icon-caret-bottom icons-size"
                  @click="() => upIndex(scope.row)"></em>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Price Methodology Name"
          prop="methodologyName"
          width="220px"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="`objectList[${scope.$index}].methodologyName`"
              :rules="{ required: true, message: $t('requiredField'), trigger: 'blur'}">
              <el-input v-model="scope.row.methodologyName"/>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          label="Price Code"
          prop="code"
        >
          <template slot-scope="scope">
            <el-form-item>
              <el-input v-model="scope.row.priceCode"/>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          label="Country"
          prop="country"
        >
          <template slot-scope="scope">
            <el-form-item>
              <el-input v-model="scope.row.country"/>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          label="Add/Remove"
          align="right"
        >
          <template slot-scope="scope">
            <el-button
              v-if="methodList.length === (scope.$index+1)"
              circle
              class="price-method"
              icon="el-icon-plus"
              size="mini" type="success"
              @click="() => addRow(scope.row)"></el-button>
            <el-button
              v-if="hasPermission($permissionFunctionCode.DELETE)"
              circle
              class="price-method"
              icon="el-icon-close"
              size="mini"
              type="danger"
              @click="() => deleteRow(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>

import { mapState } from 'vuex'
import baseMixin from '@/mixins/base-mixin'
import { ORDER_PRICE_RATE_TYPE_OPTIONS } from '@/constants.js'

const formula = [
  {
    type: 1,
    label: 'Bunkerwire'
  },
  {
    type: 2,
    label: 'MOPS'
  },
  {
    type: 3,
    label: 'Ex-Wharf'
  }
]

export default {
  name: 'manageMethodology',
  mixins: [baseMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Title'// Manage Price Methodology
    },
    type: {
      type: [Number, String],
      default: 1
    },
    priceMethodList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    const tempList = this.$_.cloneDeep(this.priceMethodList)
    return {
      ORDER_PRICE_RATE_TYPE_OPTIONS,
      formula,
      entity: {
        objectList: tempList
      },
      bunkerwireType: null,
      counter: 0
    }
  },
  mounted () {
    this.bunkerwireType = this.type
  },
  methods: {
    closeDialog () {
      this.$refs.dateDialog.handleClose()
    },

    onDialogClose (done) {
      // done()+
      this.$emit('close')
      this.$emit('update:visible', false)
    },

    async onSave () {
      // Create Price Methodology (no id and not isDeleted)

      this.$refs.mangeMethodologyForm.validate(valid => {
        if (valid) {
          this.saveMethod()
        }
      })
    },
    async saveMethod () {
      const createPriceList = this.entity.objectList.filter(x => !x.id && !x.isDeleted)
      if (createPriceList.length > 0) {
        await createPriceList.map(x => {
          this.createMethodology(x)
        })
      }

      // Update Price Methodology (have id but not isDeleted)
      const updatePriceList = this.entity.objectList.filter(x => x.id && !x.isDeleted)
      if (updatePriceList.length > 0) {
        await updatePriceList.map(x => {
          this.updateMethodology(x)
        })
      }

      // Delete Price Methodology (have id and isDeleted
      const deletePriceList = this.entity.objectList.filter(x => x.id && x.isDeleted)
      if (deletePriceList.length > 0) {
        await deletePriceList.map(x => {
          this.deleteMethodology(x)
        })
      }
      this.counter = deletePriceList.length + updatePriceList.length + createPriceList.length
    },

    handleChange () {
      this.getMethod()
    },

    getMethod () {
      const url = `${this.$apis.getMethodologyByCompanyId}/${this.currentCompany.id}/bunkerwireType/${this.bunkerwireType}`
      this.$request.get({
        url
      }).then(res => {
        if (res.code === 1000) {
          if (res.data) {
            this.entity.objectList = res.data
            this.$emit('update:priceMethodList', res.data)
          } else {
            this.entity.objectList = []
            this.$emit('update:priceMethodList', [])
          }
        }
      })
    },

    async updateMethodology (value) {
      if (value.id) {
        const url = `${this.$apis.methodologies}`
        await this.$request.put({
          url,
          data: value
        }).then(res => {
          this.counter = this.counter - 1
          if (res.code === 1000) {
            // this.getMethod()
          }
        })
      }
    },

    async createMethodology (value) {
      const methodology = {
        ...value
      }
      await this.$request.post({
        url: this.$apis.methodologies,
        data: methodology
      }).then(res => {
        this.counter = this.counter - 1
        if (res.code === 1000) {
          // this.getMethod()
        }
      })
    },

    async deleteMethodology (value) {
      const url = `${this.$apis.methodologies}/${value.id}`
      await this.$request.delete(url).then(res => {
        this.counter = this.counter - 1
        if (res.code === 1000) {
          // this.getMethod()
        }
      })
    },

    addRow (value) {
      const temp = this.entity.objectList
      const index = temp.length + 1
      const emptyRow = {
        methodologyName: '',
        isCreate: true,
        index,
        priceCode: '',
        country: '',
        companyId: this.currentCompany.id,
        bunkerwireType: this.bunkerwireType
      }
      this.entity.objectList.push(emptyRow)
    },

    deleteRow (value) {
      const deletedRow = this.entity.objectList.find(x => x.index === value.index)
      deletedRow.isDeleted = true
      this.entity.objectList.splice(this.entity.objectList.length)
      this.resetIndex(this.entity.objectList)
    },

    resetIndex (arr) {
      let startIndex = 1
      arr.map(x => {
        if (!x.isDeleted) {
          x.index = startIndex
          startIndex++
        }
        return x
      })
    },

    upIndex (value) {

      let item1 =  this.entity.objectList.find(x => x.index === value.index)
      let item2 =  this.entity.objectList.find(x => x.index === (value.index+1))

      item1.index = item1.index + 1
      item2.index = item2.index - 1
      // if (value.id) {
      //   const url = `${this.$apis.methodologies}/${value.id}/upIndex`
      //   this.$request.put({
      //     url
      //   }).then(res => {
      //     if (res.code === 1000) {
      //       this.getMethod()
      //     }
      //   })
      // }
    },

    downIndex (value) {

      let item1 =  this.entity.objectList.find(x=> x.index === value.index)
      let item2 =  this.entity.objectList.find(x=> x.index === (value.index-1))

      item1.index = item1.index - 1
      item2.index = item2.index + 1

      // if (value.id) {
      //   const url = `${this.$apis.methodologies}/${value.id}/downIndex`
      //   this.$request.put({
      //     url
      //   }).then(res => {
      //     if (res.code === 1000) {
      //       this.getMethod()
      //     }
      //   })
      // }
    }

  },
  computed: {
    ...mapState({
      currentCompany: 'currentCompany'
    }),
    methodList () {
      const temp =  this.entity.objectList
      const temp2 = temp.filter(x => x.isCreate)
      if ( this.entity.objectList.length === 0) {
        const index = temp.length + 1
        const emptyRow = {
          methodologyName: '',
          isCreate: true,
          index,
          companyId: this.currentCompany.id,
          bunkerwireType: this.bunkerwireType
        }
        temp.push(emptyRow)
      }
      return temp.filter(x => !x.isDeleted).sort( (a,b) => a.index - b.index)
    }
  },
  watch: {
    counter (newState, oldState) {
      if (newState === 0) {
        this.getMethod()
        this.closeDialog()
      }
    },
    visible (newState, oldState) {
      if (newState) {
        this.entity.objectList = this.$_.cloneDeep(this.priceMethodList)
      }
    },

    entity: {
      handler: (val, oldVal) => {

      },
      deep: true
    },

    bunkerwireType (newState, oldState) {
      this.$emit('update:type', newState)
    },

    priceMethodList (newState, oldState) {
      this.entity.objectList = this.$_.cloneDeep(this.priceMethodList)
    },

    'currentCompany.id': {
      handler (value) {
        if (value) {
          const { id: userId } = this.$store.state.user
          this.getFunctionsByUser(userId, value)
        }
      },
      immediate: true
    },
    '$route' (to) {
      const { companyId } = to.params
      this.currentCompany = this.companyList.find(item => item.id === companyId) || {}
    },
    type (newState, oldState) {
      this.bunkerwireType = newState
    }
  }
}
</script>

<style scoped>
.icons-size {
  font-size: 35px;
  color: #0F376A;
  cursor: pointer;
}

.child-right-align{
  text-align: right;
}

.icons-size[disabled] {
  color: #6C6C6C;
  cursor: not-allowed;
}

::v-deep tr.table-header-blue > th {
  background-color: #85C2F3 !important;
  color: white !important;
}

.price-method.el-button.el-button--danger.el-button--mini.is-circle {
  background-color: #F56C6C;
}

.price-method.el-button.el-button--success.el-button--mini.is-circle {
  background-color: #67C23A;
}

</style>
